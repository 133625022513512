<template>
  <v-sheet outlined class="mt-1">
    <v-card-text class="pr-2 pl-4">
      <v-row>
        <v-col sm="12" md="2" lg="1">
          {{ vector.tag ? vector.tag.title : "" }}
        </v-col>
        <v-col sm="12" md="5" lg="8">
          <v-chip
            outlined
            small
            v-for="(vectorTag, index) in vectors"
            :key="index"
            class="mb-1 mr-2"
          >
            {{ getTagTitle(vectorTag.tagId) }}: {{ vectorTag.value }}
          </v-chip>
        </v-col>
        <v-col sm="9" md="3" lg="2">
          {{ vector.updatedAt }}
        </v-col>
        <v-col sm="3" md="2" lg="1">
          <v-btn
            x-small
            icon
            color="primary"
            class="mr-2"
            @click="$emit('editInfo')"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="!vector.base"
            x-small
            icon
            color="primary"
            @click="$emit('deleteItem')"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-sheet>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "VectorInterestItem",
  props: {
    vector: Object,
  },
  data: function () {
    return {
      attributeLabels: {
        gender: this.$t("account.attribute.gender"),
        type: this.$t("account.attribute.type"),
        ageGroup: this.$t("account.attribute.age"),
        language: this.$t("account.attribute.language"),
        tagId: this.$t("account.attribute.tags"),
        stage: this.$t("account.attribute.stage"),
      },
      //List of selected related items
      selectedTags: [],
    };
  },
  computed: {
    ...mapGetters("vector", [
      "availableScopes",
      "availableActions",
      "availableSegments",
      "getColorByStatus",
    ]),
    ...mapGetters("account/donor", [
      "availableGenders",
      "availableLanguages",
      "availableStages",
      "availableAges",
      "availableLanguageLabels",
      "getAbrByLanguage",
    ]),
    formattedSegment: function () {
      let segment = {};
      _.forIn(this.vector.segment, function (value, key) {
        if (value.length) {
          segment[key] = value;
        }
      });
      return segment;
    },
    hasFormattedSegment: function () {
      return !this.isEmpty(this.formattedSegment);
    },
    vectors: function () {
      let vectors = _.merge({}, this.vector.vectors);
      return _.orderBy(vectors, ["value"], ["desc"]);
    },
  },
  methods: {
    getTagTitle: function (tagId) {
      const tag = _.find(this.vector.contentTags, { id: tagId });
      return tag ? tag.title : tagId;
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
    prepareAttributeValue: function (attribute, value) {
      let result = value;
      const component = this;
      if (_.isArray(value)) {
        return value
          .map(function (itemValue) {
            return component.prepareAttributeValue(attribute, itemValue);
          })
          .join(", ");
      }
      switch (attribute) {
        case "language": {
          let language = _.find(this.availableLanguageLabels, {
            key: value,
          });
          if (language) {
            result = language.label;
          }
          break;
        }
        case "gender": {
          let gender = _.find(this.availableGenders, {
            gender: value,
          });
          if (gender) {
            result = gender.key;
          }
          break;
        }
        case "tagId": {
          let tag = _.find(this.selectedTags, { id: value });
          result = tag ? tag.title : "";
          break;
        }
      }
      return result;
    },
  },
};
</script>
