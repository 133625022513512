<template>
  <v-card>
    <v-card-title
      class="headline primary lighten-1 white--text justify-space-between"
    >
      <span v-if="item">
        {{
          $t("recommender.editSettingsFor", {
            scope: getServiceName({ scope }),
          })
        }}
      </span>
      <span v-else>
        {{
          $t("recommender.addSettingsFor", {
            scope: getServiceName({ scope }),
          })
        }}
      </span>
      <v-btn icon color="white" :title="$t('close')" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div class="px-3" style="position: relative">
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              :clearable="true"
              :items="profTypes"
              item-text="title"
              item-value="id"
              :loading="isProfTypeLoading"
              :search-input.sync="profTypeSearch"
              menu-props="closeOnContentClick"
              @change="profTypeSearch = ''"
              hide-no-data
              hide-selected
              name="tags"
              :label="attributeLabels.tagId"
              v-model="vector.tagId"
              :disabled="!isEmpty(item)"
              :error-messages="vectorErrors.tagId"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              multiple
              :clearable="true"
              :items="tags"
              item-text="title"
              item-value="id"
              :loading="isTagLoading"
              :search-input.sync="tagSearch"
              menu-props="closeOnContentClick"
              @change="tagSearch = ''"
              small-chips
              deletable-chips
              hide-no-data
              hide-selected
              name="tags"
              :label="attributeLabels.selectedTags"
              v-model="selectedTags"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="6">
            <v-row>
              <v-col
                cols="6"
                v-for="(item, index) in vector.vectors"
                :key="index"
              >
                <v-text-field
                  :label="getTagLabel(item.tagId)"
                  v-model="vector.vectors[index].value"
                  hide-details="auto"
                >
                  <v-icon
                    small
                    slot="append"
                    color="green"
                    @click="increase(index)"
                  >
                    mdi-plus
                  </v-icon>
                  <v-icon
                    small
                    slot="prepend"
                    color="red"
                    @click="decrease(index)"
                  >
                    mdi-minus
                  </v-icon>
                </v-text-field>
              </v-col>
            </v-row>
            <v-alert
              v-if="totalVectorSum > 1"
              dense
              outlined
              type="error"
              class="mt-6 pa-2 text-caption"
            >
              Сумма весов превышает единицу
            </v-alert>
            <v-alert
              v-if="totalVectorSum < 1"
              dense
              outlined
              type="error"
              class="mt-6 pa-2 text-caption"
            >
              Сумма весов меньше единицы
            </v-alert>
            <v-alert
              v-if="isVectorExist"
              dense
              outlined
              type="error"
              class="mt-6 pa-2 text-caption"
            >
              Вектор для выбранного тега уже существует
            </v-alert>
          </v-col>
          <v-col cols="6">
            <vector-doughnut
              v-if="selectedTagItems.length"
              :chartData="vectorDataset"
              :options="options"
            ></vector-doughnut>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">
          {{ $t("close") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="updateItem"
          :disabled="totalVectorSum !== 1"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

import validator from "@/mixin/validator";
import { required } from "vuelidate/lib/validators";

import { FETCH_TAGS_PARTIAL } from "@/store/actions/tag";
import { PATCH_VECTOR } from "@/store/actions/recommender";
import { TYPE_CONTENT } from "@/store/modules/tag/getters";

import VectorDoughnut from "@/views/recommender/vector/VectorDoughnut";

export default {
  name: "ContentRecommenderForm",
  components: { VectorDoughnut },
  mixins: [validator],
  props: {
    item: Object,
    segment: Object,
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modal: 0,
      dataLoading: false,
      formErrors: null,
      vector: this.item
        ? {
            target: this.scope,
            tagId: this.item.tagId,
            vectors: this.item.vectors,
          }
        : {
            target: this.scope,
            tagId: null,
            vectors: [],
          },
      attributeLabels: {
        tagId: this.$t("recommender.vector.attributes.tagId"),
        selectedTags: this.$t("recommender.vector.attributes.selectedTags"),
      },
      //Filter values status
      isTagLoading: false,
      isProfTypeLoading: false,
      //Filter values
      tags: [],
      profTypes: [],
      //Filter entered value
      profTypeSearch: null,
      tagSearch: null,
      selectedTags: [],
      selectedTagItems: [],
      options: {
        responsive: true,
        legend: {
          position: "top",
          labels: {
            padding: 5,
            pointStyle: "circle",
            font: {
              size: 10,
              lineHeight: 1,
            },
          },
          onClick: function () {
            return false;
          },
        },
        animations: {
          tension: {
            duration: 0,
            easing: "linear",
            from: 1,
            to: 0,
            loop: false,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("service", ["getServiceName"]),
    vectorErrors: function () {
      return _.merge(
        {
          tagId: null,
        },
        this.validator.errors.vector
      );
    },
    totalVectorSum: function () {
      const total = _.map(this.vector.vectors, "value").reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
      );
      return Math.round(parseFloat(total) * 100) / 100;
    },
    vectorDataset: function () {
      const vm = this;
      let labels = [];
      let data = [];
      let colors = [];
      let left = 1;
      vm.vector.vectors.forEach(function (item) {
        const tag = vm.getTag(item.tagId);
        if (tag) {
          data.push(item.value);
          labels.push(tag.title);
          left -= item.value;
          colors.push(tag.settings.color ? tag.settings.color.hex : "#CFD8DC");
        }
      });
      if (left > 0) {
        data.push(Math.round(parseFloat(left) * 100) / 100);
        labels.push("Не установленно");
        colors.push("#CFD8DC");
      }
      return {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: colors,
          },
        ],
      };
    },
    isVectorExist: function () {
      return (
        this.formErrors &&
        this.formErrors.tagId &&
        this.formErrors.tagId.indexOf("not_unique") > -1
      );
    },
  },
  watch: {
    selectedTags: function () {
      const vm = this;
      const existedVectors = _.keyBy(this.vector.vectors, function (item) {
        return item.tagId;
      });
      let vectors = [];
      vm.selectedTags.forEach(function (tagId) {
        vectors.push({
          tagId,
          value: _.get(existedVectors, [tagId, "value"], 0),
        });
      });
      this.vector.vectors = vectors;
      this.$store
        .dispatch("tag/" + FETCH_TAGS_PARTIAL, {
          limit: 50,
          offset: 0,
          search: {
            id: this.selectedTags,
            type: TYPE_CONTENT,
          },
        })
        .then(
          (payload) => {
            this.selectedTagItems = payload.data.batch;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    tagSearch: function (value) {
      this.isTagLoading = true;
      this.$store
        .dispatch("tag/" + FETCH_TAGS_PARTIAL, {
          limit: 50,
          offset: 0,
          search: {
            title: value,
            type: TYPE_CONTENT,
            selectedIds: this.selectedTags,
          },
        })
        .then(
          (payload) => {
            this.isTagLoading = false;
            this.tags = payload.data.batch;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    profTypeSearch: function (value) {
      this.isProfTypeLoading = true;
      this.$store
        .dispatch("tag/" + FETCH_TAGS_PARTIAL, {
          limit: 50,
          offset: 0,
          search: {
            title: value,
            selectedIds: this.selectedTags,
            excludeType: [TYPE_CONTENT],
            level: 1,
          },
        })
        .then(
          (payload) => {
            this.isProfTypeLoading = false;
            this.profTypes = payload.data.batch;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  methods: {
    updateItem: function () {
      if (this.validate()) {
        this.dataLoading = true;
        let { vector } = this;
        vector.target = this.scope;
        this.$store
          .dispatch("vector/" + PATCH_VECTOR, {
            vector,
            id: this.item ? this.item.id : null,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.$emit("submitted");
              } else {
                this.formErrors = payload.errors;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
    increase: function (index) {
      let value =
        this.vector.vectors[index].value < 0.99
          ? this.vector.vectors[index].value + 0.01
          : 1;
      this.vector.vectors[index].value =
        Math.round(parseFloat(value) * 100) / 100;
    },
    decrease: function (index) {
      let value =
        this.vector.vectors[index].value > 0.01
          ? this.vector.vectors[index].value - 0.01
          : 0;
      this.vector.vectors[index].value =
        Math.round(parseFloat(value) * 100) / 100;
    },
    getTagLabel: function (id) {
      const tag = _.find(this.selectedTagItems, { id });
      return tag ? tag.title : "";
    },
    getTag: function (id) {
      return _.find(this.selectedTagItems, { id });
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
  },
  mounted: function () {
    if (this.item) {
      this.selectedTags = _.map(this.item.vectors, "tagId");
    }
    this.isProfTypeLoading = true;
    this.$store
      .dispatch("tag/" + FETCH_TAGS_PARTIAL, {
        limit: 50,
        offset: 0,
        search: {
          id: this.vector.tagId,
          excludeType: [TYPE_CONTENT],
          level: 1,
        },
      })
      .then(
        (payload) => {
          this.isProfTypeLoading = false;
          this.profTypes = payload.data.batch;
        },
        (err) => {
          console.log("Err", err);
        }
      )
      .catch(() => {});

    this.isTagLoading = true;
    this.$store
      .dispatch("tag/" + FETCH_TAGS_PARTIAL, {
        limit: 50,
        offset: 0,
        search: {
          id: this.selectedTags,
          type: TYPE_CONTENT,
        },
      })
      .then(
        (payload) => {
          this.isTagLoading = false;
          this.tags = payload.data.batch;
        },
        (err) => {
          console.log("Err", err);
        }
      )
      .catch(() => {});
  },
  validations: function () {
    return {
      vector: {
        tagId: { required },
      },
    };
  },
};
</script>

<style scoped>
.row + .row,
.col,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 0;
  padding-bottom: 0;
}

.row + .row {
  margin-top: 0;
}
</style>
