<template>
  <v-col sm="12" md="7" lg="5">
    <v-autocomplete
      :items="tags"
      item-text="title"
      item-value="id"
      :loading="isTagLoading"
      :search-input.sync="tagSearch"
      menu-props="closeOnContentClick"
      @change="tagSearch = ''"
      deletable-chips
      multiple
      hide-no-data
      hide-selected
      small-chips
      name="tags"
      :label="label"
      v-model="itemValue"
      @input="handleInput"
    >
      <v-icon
        slot="append"
        :title="$t('removeFilter')"
        @click="$emit('remove')"
      >
        mdi-close
      </v-icon>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { FETCH_TAGS_PARTIAL } from "@/store/actions/tag";
import { TYPE_CONTENT } from "@/store/modules/tag/getters";

export default {
  name: "ContentTagSearch",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Array,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
      closeOnContentClick: true,
      //List of selected related items
      //Filter values status
      isTagLoading: false,
      //Filter values
      tags: [],
      //Filter entered value
      tagSearch: "",
    };
  },
  watch: {
    tagSearch: function () {
      this.fetchTags();
    },
    value: {
      handler: function () {
        this.itemValue = this.value;
        this.fetchTags();
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
    fetchTags: function () {
      this.isTagLoading = true;
      this.$store
        .dispatch("tag/" + FETCH_TAGS_PARTIAL, {
          limit: 50,
          offset: 0,
          search: {
            title: this.tagSearch,
            selectedIds: this.itemValue,
            type: TYPE_CONTENT,
            level: 1,
          },
        })
        .then(
          (payload) => {
            this.isTagLoading = false;
            this.tags = payload.data.batch;
          },
          () => {}
        )
        .catch(() => {});
    },
  },
  mounted: function () {
    this.fetchTags();
  },
};
</script>
